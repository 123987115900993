$(document).ready(function () {
    $(document).on("click", ".menu__trigger", function () {
        $(this).addClass('active');
        $('#layout-menu').addClass('active');
    });

    $(document).on("click", ".menu_close", function () {
        $(this).addClass('active');
        $('#layout-menu').removeClass('active');
    });
});